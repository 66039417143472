var render, staticRenderFns
import script from "./GridEditNavigationCell.vue?vue&type=script&lang=js&"
export * from "./GridEditNavigationCell.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/pbk/pim/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5d7202d5')) {
      api.createRecord('5d7202d5', component.options)
    } else {
      api.reload('5d7202d5', component.options)
    }
    
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/Grid/Layout/Table/Cells/Navigation/GridEditNavigationCell.vue"
export default component.exports