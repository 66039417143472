import { render, staticRenderFns } from "./GridSuffixPresentationCell.vue?vue&type=template&id=56d87bdc&scoped=true&functional=true&"
import script from "./GridSuffixPresentationCell.vue?vue&type=script&lang=js&"
export * from "./GridSuffixPresentationCell.vue?vue&type=script&lang=js&"
import style0 from "./GridSuffixPresentationCell.vue?vue&type=style&index=0&id=56d87bdc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "56d87bdc",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/pbk/pim/frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56d87bdc')) {
      api.createRecord('56d87bdc', component.options)
    } else {
      api.rerender('56d87bdc', component.options)
    }
    module.hot.accept("./GridSuffixPresentationCell.vue?vue&type=template&id=56d87bdc&scoped=true&functional=true&", function () {
      api.rerender('56d87bdc', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "modules/@ergonode/ui/src/components/Grid/Layout/Table/Cells/Presentation/GridSuffixPresentationCell.vue"
export default component.exports